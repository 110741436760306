@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@700&family=Noto+Sans+KR:wght@400;500;700&family=Roboto:wght@300;400;500;700&family=Playfair+Display&display=swap");

body {
  background-color: white;
  margin: 0;
  /* touch-action: none; */
}

@media (orientation: landscape) {
  #root {
    overflow: scroll;
  }
}

/* @media (min-width: 360px) {
  .App {
    width: 360px;
    margin: 0 auto;
  }
}

@media (max-width: 360px) {
  .App {
    width: 100%;
  }
} */

.App {
  background-color: white;
  display: flex;
  flex-direction: column;
  min-height: 100dvh;
}

@supports not (min-height: 100dvh) {
  .App {
    min-height: calc(var(--vh, 1vh) * 100);
  }
}

/* Button */

.Button {
  cursor: pointer;
  border: none;
  white-space: nowrap;
  font-size: 16px;
  font-family: "Noto Sans KR";
  font-weight: bold;
}

.Button_default {
  background-color: #ff9500;
  color: white;
}

.New {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.image-selector-wrapper::before {
  content: "";
  position: absolute;
  background-color: #ff9500;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/* ImageSelector */
.image-selector-wrapper {
  background-color: #ff9500;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ImageSelector {
  width: 120px;
  height: 120px;
  background-color: #ffd498;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 3px dashed white;
  cursor: pointer;
}

.ImageSelector > input {
  display: none;
}

.ImageSelector > div {
  font-family: "Playfair Display";
  font-size: 80px;
  color: white;
}

.image-selector-description {
  margin-top: 20px;
  font-family: "Noto Sans KR";
  text-align: center;
  color: white;
}

.image-selector-description > span:first-child {
  font-weight: bold;
  font-size: 18px;
}

.image-selector-description > span:nth-child(2) {
  margin-top: 10px;
  font-size: 14px;
}

.zoomable-image-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#upload-image-button {
  width: 100%;
  height: 56px;
}

#upload-image-button:hover {
  background-color: #ff9500cc;
}

#upload-image-button .MuiLoadingButton-loadingIndicator {
  color: white;
}

#upload-image-button
  .MuiLoadingButton-loadingIndicator
  .MuiCircularProgress-root {
  width: 20px !important;
  height: 20px !important;
}

#upload-image-button
  .MuiLoadingButton-loadingIndicator
  .MuiCircularProgress-root
  circle {
  stroke-width: 6;
}

#lang-change-button {
  width: 100%;
  height: 56px;
}

#lang-change-button:hover {
  background-color: #ffffff;
}

#lang-change-button .MuiLoadingButton-loadingIndicator {
  color: white;
}

#lang-change-button
  .MuiLoadingButton-loadingIndicator
  .MuiCircularProgress-root {
  width: 20px !important;
  height: 20px !important;
}

#lang-change-button
  .MuiLoadingButton-loadingIndicator
  .MuiCircularProgress-root
  circle {
  stroke-width: 6;
}

.zoomable-image-wrapper > canvas {
  display: none;
}

@media (orientation: portrait) {
  @supports (width: 100dvw) {
    .ZoomableImage {
      height: calc(100dvw * 4 / 3);
      max-height: calc(320px * 4 / 3);
    }
  }

  @supports not (width: 100dvw) {
    .ZoomableImage {
      height: calc(100vw * 4 / 3);
      max-height: calc(320px * 4 / 3);
    }
  }
}

@media (orientation: landscape) {
  .ZoomableImage {
    height: calc(320px * 4 / 3);
  }
}

.ZoomableImage {
  width: 100%;
  max-width: 320px;
  position: relative;
  /* margin-top: 48px; */
  -webkit-box-shadow: 0 4px 26px rgba(0, 0, 0, 0.24);
  box-shadow: 0 4px 26px rgba(0, 0, 0, 0.24);
}

@media (min-width: 361px) {
  .ZoomableImage {
    /* margin-top: 32px; */
  }
}

@media (min-width: 692px) {
  .ZoomableImage {
    /* margin-top: 48px; */
  }
}

.ZoomableImage > img {
  aspect-ratio: 3/4;
}

.ZoomableImage > .close-button {
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  background-clip: content-box;
  padding: 16px;
  border-radius: 50%;
  background-color: #696969bb;
  z-index: 10;
  transition: opacity 0.5s;
  -moz-transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
}

.ZoomableImage > .close-button > .line {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 3px;
  border-radius: 2px;
  background: white;
  transform-origin: center;
  transform: translate(-50%, -50%) rotate(45deg);
}

.ZoomableImage > .close-button > .line:nth-child(2) {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.ZoomableImage > #grid {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  background-color: transparent;
  grid-gap: 0;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.75s;
  -moz-transition: opacity 0.75s;
  -webkit-transition: opacity 0.75s;
}

.ZoomableImage > #grid > div:not(:nth-last-child(-n + 3)) {
  border-bottom: 1px solid white;
}

.ZoomableImage > #grid > div:not(:nth-child(3n)) {
  border-right: 1px solid white;
}

.rotate_90_clockwise_img {
  width: 32px;
  height: 42px;
  margin-top: 24px;
}

@media (min-width: 361px) {
  .rotate_90_clockwise_img {
    margin-top: 16px;
  }
}

@media (min-width: 692px) {
  .ZoomableImage {
    /* margin-top: 40px; */
  }
}
